import config from 'config';
import { Bootstrapper } from './environment/bootstrapper';

const sptrackerConfig: any = {
	environment: (config.stage) ? config.stage : 'dev',
	team: 'xm',
	product: 'WishlistService',
	category: 'Wishlist',
};

const bootstrapper = new Bootstrapper(
	window['EventAggregationService'],
	window['SessionService'],
	config.wishlistServiceBaseUrl,
	sptrackerConfig,
);

bootstrapper.configureEnvironment();
bootstrapper.setupApplication();
