import {
	AddToWishlistCommand,
	GetWishlistQuery,
	GetWishlistQueryResult,
	IAddToWishlistCommandHandler,
	IGetWishlistQueryHandler,
	IRemoveFromWishlistCommandHandler,
	ISetWishlistOrderCommandHandler,
	RemoveFromWishlistCommand,
	SetWishlistOrderCommand,
	Wishlist,
} from '@studyportals/wishlist-service-core';

import { IWishlistService } from '../../../interfaces/wishlist-service';


export class LocalWishlistService implements IWishlistService {

	private userId = 'local-wishlist';

	constructor(
		 private addToWishlistCommandHandler: IAddToWishlistCommandHandler,
		private getWishlistQueryHandler: IGetWishlistQueryHandler,
		private removeFromWishlistCommandHandler: IRemoveFromWishlistCommandHandler,
		 private setWishlistOrderCommandHandler: ISetWishlistOrderCommandHandler,
	) { }

	public async addFavourite(studyId: number): Promise<void> {

		const wishlist = await this.getWishlist();
		const command = this.createAddToWishlistCommand(studyId, wishlist);
		await this.addToWishlistCommandHandler.handle(command);
	}

	public async getWishlist(): Promise<Wishlist> {

		const command = this.createGetWishlistQuery();
		const result: GetWishlistQueryResult = await this.getWishlistQueryHandler.handle(command);
		return result.wishlist;
	}

	public async removeFavourite(studyId: number): Promise<void> {

		const wishlist = await this.getWishlist();
		const command = this.createRemoveFromWishlistCommand(studyId, wishlist);
		await this.removeFromWishlistCommandHandler.handle(command);
	}

	public async setOrder(targetStudyId: number, position: number): Promise<void> {
		const wishlist = await this.getWishlist();
		const command = this.createSetWishlistOrderCommand(targetStudyId, position, wishlist);
		await this.setWishlistOrderCommandHandler.handle(command);
	}

	private createGetWishlistQuery(): GetWishlistQuery {

		return new GetWishlistQuery(this.userId);
	}

	private createAddToWishlistCommand(studyId: number, wishlist: Wishlist): AddToWishlistCommand {

		return new AddToWishlistCommand(this.userId, studyId, wishlist.stateHash);
	}

	private createRemoveFromWishlistCommand(studyId: number, wishlist: Wishlist): RemoveFromWishlistCommand {

		return new RemoveFromWishlistCommand(this.userId, studyId, wishlist.stateHash);
	}

	private createSetWishlistOrderCommand(targetStudyId: number, position: number, wishlist: Wishlist): SetWishlistOrderCommand {

		return new SetWishlistOrderCommand(this.userId, targetStudyId, position, wishlist.stateHash);
	}
}
