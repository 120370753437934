import { FavouriteAdded, FavouriteRemoved } from '@studyportals/wishlist-service-core';
import { WishlistOrderChanged } from '@studyportals/wishlist-service-core/src/domain/events/wishlist-order-changed';
import {
	IEvent,
	IEventAggregationService,
	ISubscriber,
	SessionCreatedEvent,
	SessionDestroyedEvent,
	SessionServiceReadyEvent
} from '../../interfaces';
import { ITokenBasedSession, ITokenBasedSessionService } from '../../interfaces';
import { IWishlistService } from '../../interfaces';
import { InternalSessionServiceEventType } from '../session-management/domain/enums/internal-session-service-event-type';
import { WishlistEventType } from '../wishlist-service/domain/enums/wishlist-event-type';
import { GroupEventEmitter } from './group-event-emitter';
import { WebSocketGatewaySubscriber } from './web-socket-gateway-subscriber';
import { IWebSocketGateway } from './web-socket-gateway.interface';

export class EventAggregationService implements IEventAggregationService {

	private groupEventEmitter: GroupEventEmitter;

	constructor(
		private webSocketGateway: IWebSocketGateway
	) {
		this.groupEventEmitter = new GroupEventEmitter();
	}


	public subscribeToWishlistServiceReadyEvent(subscriber: ISubscriber<IWishlistService>, replay?: boolean): void {
		this.groupEventEmitter.subscribe(WishlistEventType.WISHLIST_SERVICE_READY, subscriber, replay);
	}

	public publishWishlistServiceReadyEvent(wishlistService: IWishlistService): void {
		this.groupEventEmitter.notify(WishlistEventType.WISHLIST_SERVICE_READY, wishlistService);
	}

	public subscribeToFavouriteAddedEvent(subscriber: ISubscriber<FavouriteAdded>, replay?: boolean): void {
		this.groupEventEmitter.subscribe(WishlistEventType.FAVOURITE_ADDED, subscriber, replay);
	}

	public publishFavouriteAddedEvent(event: FavouriteAdded): void {
		this.groupEventEmitter.notify(WishlistEventType.FAVOURITE_ADDED, event);
	}

	public subscribeToFavouriteRemovedEvent(subscriber: ISubscriber<FavouriteRemoved>, replay?: boolean): void {
		this.groupEventEmitter.subscribe(WishlistEventType.FAVOURITE_REMOVED, subscriber, replay);
	}

	public publishFavouriteRemovedEvent(event: FavouriteRemoved): void {
		this.groupEventEmitter.notify(WishlistEventType.FAVOURITE_REMOVED, event);
	}

	public subscribeToWishlistOrderChangedEvent(subscriber: ISubscriber<WishlistOrderChanged>, replay?: boolean): void {
		this.groupEventEmitter.subscribe(WishlistEventType.WISHLIST_ORDER_CHANGED, subscriber, replay);
	}

	public publishWishlistOrderChangedEvent(event: WishlistOrderChanged): void {
		this.groupEventEmitter.notify(WishlistEventType.WISHLIST_ORDER_CHANGED, event);
	}

	public subscribeToWebSocketEvent(eventType: string, subscriber: ISubscriber<any>): void {
		this.webSocketGateway.addSubscriber(new WebSocketGatewaySubscriber(eventType, subscriber));
	}

	public subscribeTo(eventType: string, subscriber: ISubscriber<IEvent>, replay?: boolean): void {
		this.groupEventEmitter.subscribe(eventType, subscriber, replay);
	}

	public publishTo(eventType: string, event: IEvent): void {
		this.groupEventEmitter.notify(eventType, event);
	}
}
