import { PortalMap, ProgrammeCardClient } from "@studyportals/domain-client";
import {Favourite, ISerializedWishlist, Study, Wishlist} from '@studyportals/wishlist-service-core';
import { SerializableWishlist } from "../entities/serializable-wishlist";

export class WishlistFactory {

	private programmeCardClient: ProgrammeCardClient;

	constructor() {
		this.programmeCardClient = new ProgrammeCardClient(
			'url',
			'key',
			this.getRandomInt(1, 100),
			this.getPortalMap()
		)
	}

	public translateSerializedWishlist(serializedWishlist: ISerializedWishlist): SerializableWishlist {
		const wishlist = new SerializableWishlist(serializedWishlist.userId, serializedWishlist);

		serializedWishlist.favourites.forEach((serializedFavourite) => {
			const study = this.createStudy(serializedFavourite);
			const dateAdded = new Date(serializedFavourite.dateAdded);
			const favourite = new Favourite(study, dateAdded);

			wishlist.addFavourite(favourite);
		});

		return wishlist;
	}

	public createStudy(serializedFavourite: any): Study {
		const studyInfo = serializedFavourite.study;
		let card = undefined;

		if(studyInfo.card !== undefined) {
			card = this.programmeCardClient.fromDescription(studyInfo.card)
		}

		return new Study(studyInfo.id, card);
	}

	private getPortalMap(): undefined|PortalMap {
		if (!window['PortalMap']) {
			return undefined;
		}

		return new Map(Object.entries(window['PortalMap'])) as PortalMap;
	}

	private getRandomInt(min, max): number {
		min = Math.ceil(min);
		max = Math.floor(max);

		// The maximum is exclusive and the minimum is inclusive
		return Math.floor(Math.random() * (max - min) + min);
	}
}
