export class CallbackSubscriberLegacy {

    protected callback: (error, data) => void;
    constructor(callback: (error, data) => void) {

        this.callback = callback;
    }

    notify(error, data): void {

        try {
            this.callback(error, data);
        } catch (error) {
            console.error(error);
        }
    }

}
