import { FavouriteRemoved } from '@studyportals/wishlist-service-core';
import { IEventAggregationService } from '../../../interfaces/event-aggregation-service/event-aggregation-service.interface';
import { ISubscriber } from '../../../interfaces/event-aggregation-service/subscriber.interface';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class WebSocketFavouriteRemovedSubscriber implements ISubscriber<FavouriteRemoved> {

	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache,
	) { }

	async notify(event: FavouriteRemoved): Promise<void> {

		await this.removeFavouriteToCachedWishlist(event);

		this.eventAggregationService.publishFavouriteRemovedEvent(
			new FavouriteRemoved(
				event.userId,
				event.studyId,
				event.eventId,
				new Date(event.dateRemoved),
			),
		);
	}

	private async removeFavouriteToCachedWishlist(event: FavouriteRemoved): Promise<void> {

		const wishlist = await this.wishlistCache.getWishlist();
		if (wishlist) {
			wishlist.removeFavourite(event.studyId);
			await this.wishlistCache.setWishlist(wishlist);
		}
	}

}
