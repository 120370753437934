export class SessionNotFoundError extends Error {

	public error: Error;

	constructor(message = '', error: Error = undefined) {
		super(message);
		this.name = 'SessionNotFoundError';
		this.error = error;

		Object.setPrototypeOf(this, SessionNotFoundError.prototype);
	}
}
