import { CallbackSubscriber } from './callback-subscriber';

export class OnceCallbackSubscriber<EventType> extends CallbackSubscriber<EventType> {

	private lastEventSent = '';

	constructor(callback: (event: EventType) => Promise<void>) {
		super(callback);
	}

	async notify(event: EventType): Promise<void> {

		const serializedEvent = JSON.stringify(event);
		if (this.lastEventSent === serializedEvent) {

			return;
		}
		this.lastEventSent = serializedEvent;
		await super.notify(event);
	}

}
