import {InterestType} from '../enums/interest-type.enum';
import {IServicelayerDriver} from '../interfaces/i-servicelayer-driver';
import {IStudentapiDriver} from '../interfaces/i-studentapi-driver';

export class Interests {

	public studentAPI: IStudentapiDriver;
	public serviceLayer: IServicelayerDriver;
	public interests: Object;

	public clearInterestsCache() {

		this.interests = {};
		this.serviceLayer.clearCache('interests');
	}

	public addInterests(type: string, ids: number[]): Promise<number[]> {

		return this.studentAPI.addInterests(type, ids).then((interests) => {

			this.interests[type] = interests;
			return interests;

		});
	}

	public removeInterests(type: string, ids: number[]): Promise<number[]> {

		return this.studentAPI.removeInterests(type, ids).then((interests) => {

			this.interests[type] = interests;
			return interests;

		});
	}

	public getInterestsData(type: InterestType): Promise<object[]> {

		return this.getInterests(type).then((interestIds) => {

			if (Array.isArray(interestIds) && interestIds.length > 0) {

				return this.serviceLayer.getInterests(type, interestIds);
			}
			return [];

		});
	}

	public getInterests(type: InterestType): Promise<number[]> {

		return new Promise((resolve, reject) => {

			if (this.interests[type] === undefined) {

				return this.studentAPI.getInterests(type).then((interests) => {

					if (Array.isArray(interests) && interests.length > 0) {

						this.interests[type] = interests;
						return resolve(interests);
					}
					this.interests[type] = [];
					return resolve([]);
				});
			} else {

			   return resolve(this.interests[type]);
			}

		});
	}
}
