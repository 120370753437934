import {
	IEventAggregationService,
	ISessionService,
	ISubscriber,
	SessionServiceReadyEvent,
} from '../../../../interfaces';
import { Credentials } from '../entities/credentials';
import { TokenBasedSessionFactory } from '../factories/token-based-session-factory';

export class RefreshTokenFromUrlSubscriber implements ISubscriber<SessionServiceReadyEvent> {

	constructor(
		private eventAggregationService: IEventAggregationService,
		private tokenSessionFactory: TokenBasedSessionFactory,
	) {

	}

	public subscribe(): void {
		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
	}

	public async notify(event: SessionServiceReadyEvent): Promise<void> {
		if (document.readyState === 'complete') {
			await this.setSessionBasedOnRefreshTokenInUrl(event.sessionService);
		} else {
			document.addEventListener('readystatechange', async (e) => {
				const target = e.target as any;
				if (target.readyState === 'complete') {
					await this.setSessionBasedOnRefreshTokenInUrl(event.sessionService);
				}
			});
		}
	}

	private async setSessionBasedOnRefreshTokenInUrl(sessionService: ISessionService): Promise<void> {
		const refreshToken = (new URL(window.location as any)).searchParams.get('refreshToken');
		if (null !== refreshToken) {
			await this.setSessionFromCredentials(sessionService, new Credentials(null, refreshToken));
		}
	}

	private async setSessionFromCredentials(sessionService: ISessionService, credentials: Credentials): Promise<void> {
		const session = this.tokenSessionFactory.create(credentials);
		await sessionService.setSession(session);
		this.removeRefreshTokenFromURL();
	}

	private removeRefreshTokenFromURL(): void {

		const url = new URL(location.href);
		url.searchParams.delete('refreshToken');
		window.history.pushState({}, document.title, url.toString());
	}

}
