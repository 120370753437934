import { applyMixins } from '../../libs/mixins';
import { Account } from './classes/account';
import { Data } from './classes/data';
import { Disciplines } from './classes/disciplines';
import { Interests } from './classes/interests';
import { IServicelayerDriver } from './interfaces/i-servicelayer-driver';
import { IStudentapiDriver } from './interfaces/i-studentapi-driver';

export class Student implements Interests, Data, Disciplines, Account {

	public constructor(studentAPI: IStudentapiDriver, serviceLayer: IServicelayerDriver, trackError: Function) {
		this.studentAPI = studentAPI;
		this.serviceLayer = serviceLayer;
		this.readyCallbacks = [];
		this.interests = {};
		this.readyFlag = false;
		this.trackError = trackError;
	}

	public studentAPI: IStudentapiDriver;
	public serviceLayer: IServicelayerDriver;
	private readyCallbacks: Function[];
	private readyFlag: Boolean;

	public interests: Object;
	public disciplines: number[];

	private trackError: Function;

	// Import functions from interests
	public clearInterestsCache: () => void;
	public addInterests: (type: string, ids: number[]) => Promise<number[]>;
	public removeInterests: (type: string, ids: number[]) => Promise<number[]>;
	public getInterests: () => Promise<number[]>;
	public getInterestsData: () => Promise<object[]>;

	// Import functions from data
	public getScope: (scope: string) => Promise<object>;
	public getScopes: (scopes: string[]) => Promise<object>;
	public setValue: (key: string, value: any) => Promise<object>;
	public setGPA: (key: string, value: any) => Promise<Object>;
	public setName: (name: string) => Promise<Object>;
	public setValues: (data: object) => Promise<object>;

	// Import functions from disciplines
	public addDisciplines: (ids: number[]) => Promise<number[]>;
	public removeDisciplines: (ids: number[]) => Promise<number[]>;
	public getDisciplines: () => Promise<number[]>;
	public getDisciplinesData: () => Promise<object[]>;

	// Import forgot password functions
	public confirmForgotPassword: (confirmationCode: string, password: string) => Promise<Boolean>;
	public disableAccount: () => Promise<object>;
	public changePassword: (oldPassword: string, password: string, passwordConfirmation: string) => Promise<object>;

	public ready(callback) {

		this.readyCallbacks.push(callback);
		if (this.readyFlag) {

			this.notifyReadyCallbacks();
		}
	}

	private notifyReadyCallbacks() {

		let callback = this.readyCallbacks.shift();
		callback(this);
	}

	public clearCache() {

		this.clearInterestsCache();
	}
}

applyMixins(Student, [Interests, Data, Disciplines, Account]);
