import {
	DefaultAddToWishlistCommandHandler,
	DefaultGetWishlistQueryHandler,
	DefaultRemoveFromWishlistCommandHandler,
	SetWishlistOrderCommandHandler,
} from '@studyportals/wishlist-service-core';

import SPTracker from '../../../libs/Tracking/SPTracker';
import { DataStorageDriver } from '../../common/data-storage-driver';
import { CallbackSubscriber } from '../../event-aggregation-service/callback-subscriber';
import { IEventAggregationService } from '../../../interfaces/event-aggregation-service';
import {
	ISessionService,
	SessionCreatedEvent,
	SessionDestroyedEvent,
	SessionServiceReadyEvent
} from '../../../interfaces/session-management';
import { DecisionMakingApiWishlistService } from '../application/decision-making-api-wishlist-service';
import { LocalWishlistService } from '../application/local-wishlist-service';
import { QueueWishlistService } from '../application/queue-wishlist-service';
import { RetryCommandWishlistService } from '../application/retry-command-wishlist-service';
import { IWishlistService } from '../../../interfaces/wishlist-service';
import { WebSocketFavouriteAddedAdapter } from '../domain/websocket-favourite-added-adapter';
import { WebSocketFavouriteRemovedAdapter } from '../domain/websocket-favourite-removed-adapter';
import { WebsocketWishlistOrderChangedAdapter } from '../domain/websocket-wishlist-order-changed-adapter';
import { EventAggregationServiceEventEmitter } from '../infrastructure/event-aggregation-event-emitter';
import { LocalWishlistRepository } from '../infrastructure/local-wishlist-repository';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class Bootstrapper {

	private localWishlistService: IWishlistService = null;
	private remoteWishlistService: IWishlistService = null;
	private localWishlistRepository: LocalWishlistRepository = null;
	private queueWishlistService: QueueWishlistService = new QueueWishlistService();
	private wishlistCache: WishlistCache = null;

	constructor(
		private eventService: IEventAggregationService,
		private sessionService: ISessionService,
		private wishlistBaseUrl: string,
		private spTrackerConfig: any) {
	}

	public configureEnvironment(): void {
		const dataStorageDriver = new DataStorageDriver();
		this.wishlistCache = new WishlistCache(
			dataStorageDriver,
		);

		this.remoteWishlistService = this.createRemoteWishlistService();
		this.localWishlistService = this.createLocalWishlistService(dataStorageDriver);
	}

	public setupApplication(): void {
		this.setupOnSessionServiceReady();
		this.eventService.subscribeTo(SessionCreatedEvent.EventType, new CallbackSubscriber(() => this.onSessionCreated()));
		this.eventService.subscribeTo(SessionDestroyedEvent.EventType, new CallbackSubscriber(() => this.onSessionDestroyed()));
		new WebSocketFavouriteAddedAdapter(this.eventService, this.wishlistCache).initialize();
		new WebSocketFavouriteRemovedAdapter(this.eventService, this.wishlistCache).initialize();
		new WebsocketWishlistOrderChangedAdapter(this.eventService, this.wishlistCache).initialize();
	}

	private setWishlistService(wishlistService: IWishlistService): void {
		this.queueWishlistService.setWishlistService(wishlistService);
	}

	private async synchroniseLocalWishlistToRemote(): Promise<void> {
		const localWishlist = await this.localWishlistService.getWishlist();
		if (localWishlist !== null) {

			await this.remoteWishlistService.getWishlist();

			for (let favouriteIndex in localWishlist.favourites) {
				const favourite = localWishlist.favourites[favouriteIndex];
				const studyId = favourite.study.id;
				await this.remoteWishlistService.addFavourite(studyId);
			}

			await this.localWishlistRepository.removeWishlist();
		}
	}

	private async onSessionDestroyed(): Promise<void> {
		this.setWishlistService(this.localWishlistService);
		await this.wishlistCache.clearCache();
	}

	private async onSessionCreated(): Promise<void> {
		this.setWishlistService(this.remoteWishlistService);
		await this.synchroniseLocalWishlistToRemote();
	}

	private setQueueWishlistServiceOnWindow(): void {
		window['wishlistService'] = this.queueWishlistService;
	}

	private setupOnSessionServiceReady(): void {
		this.eventService.subscribeTo(SessionServiceReadyEvent.EventType, new CallbackSubscriber(() => this.onSessionServiceReady()), true);
	}

	private async onSessionServiceReady(): Promise<void> {
		const session = await this.sessionService.getSession();

		if (session === null) {
			this.setWishlistService(this.localWishlistService);
		} else {
			this.setWishlistService(this.remoteWishlistService);
			this.synchroniseLocalWishlistToRemote();
		}

		this.setQueueWishlistServiceOnWindow();
		this.eventService.publishWishlistServiceReadyEvent(this.queueWishlistService);
	}

	private createLocalWishlistService(dataStorageDriver: DataStorageDriver): IWishlistService {
		const eventEmitter = new EventAggregationServiceEventEmitter(this.eventService);
		const localWishlistRepository = new LocalWishlistRepository(dataStorageDriver);
		this.localWishlistRepository = localWishlistRepository;

		const addToWishlistCommandHandler = new DefaultAddToWishlistCommandHandler(localWishlistRepository, eventEmitter);
		const removeFromWishlistCommandHandler = new DefaultRemoveFromWishlistCommandHandler(localWishlistRepository, eventEmitter);
		const setWishlistOrderCommandHandlerCommandHandler = new SetWishlistOrderCommandHandler(localWishlistRepository, eventEmitter);
		const getWishlistCommandHandler = new DefaultGetWishlistQueryHandler(localWishlistRepository);

		return new RetryCommandWishlistService(
			new LocalWishlistService(
				addToWishlistCommandHandler,
				getWishlistCommandHandler,
				removeFromWishlistCommandHandler,
				setWishlistOrderCommandHandlerCommandHandler
			)
		);
	}

	private createRemoteWishlistService(): IWishlistService {
		const tracker = new SPTracker(this.spTrackerConfig);

		return new RetryCommandWishlistService(
			new DecisionMakingApiWishlistService(
				this.sessionService,
				this.wishlistBaseUrl,
				this.wishlistCache,
				tracker
			)
		)
	}
}
