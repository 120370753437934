import { ISubscriber } from '../../interfaces/event-aggregation-service/subscriber.interface';

export class CallbackSubscriber<EventType> implements ISubscriber<EventType> {

	protected callback: (event: EventType) => Promise<void>;

	constructor(callback: (event: EventType) => Promise<void>) {

		this.callback = callback;
	}

	async notify(event: EventType): Promise<void> {

		try {
			await this.callback(event);
		} catch (error) {
			console.error(error);
		}

	}

}
