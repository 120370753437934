import {WishlistOrderChanged} from '@studyportals/wishlist-service-core';
import {IEventAggregationService} from '../../../interfaces/event-aggregation-service/event-aggregation-service.interface';
import {ISubscriber} from '../../../interfaces/event-aggregation-service/subscriber.interface';
import {WishlistCache} from '../infrastructure/wishlist-cache';

export class WebsocketWishlistOrderChangedSubscriber implements ISubscriber<WishlistOrderChanged> {

	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache,
	) { }

	async notify(event: WishlistOrderChanged): Promise<void> {

		await this.setOrderOnCachedWishlist(event);

		this.eventAggregationService.publishWishlistOrderChangedEvent(
			new WishlistOrderChanged(
				event.userId,
				event.targetStudyId,
				event.referenceStudyId,
				event.eventId,
				new Date(event.dateChanged),
			),
		);
	}

	private async setOrderOnCachedWishlist(event: WishlistOrderChanged): Promise<void> {

		const wishlist = await this.wishlistCache.getWishlist();

		if (wishlist) {
			wishlist.changeOrder(event.targetStudyId, event.referenceStudyId);
			await this.wishlistCache.setWishlist(wishlist);
		}
	}

}
