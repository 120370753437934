import { FavouriteRemoved } from '@studyportals/wishlist-service-core';
import { IEventAggregationService } from 'interfaces/event-aggregation-service/event-aggregation-service.interface';

import { WishlistCache } from '../infrastructure/wishlist-cache';
import { WebSocketFavouriteRemovedSubscriber } from './websocket-favourite-removed-subscriber';

export class WebSocketFavouriteRemovedAdapter {

	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache) {
	}

	public initialize(): void {
		this.eventAggregationService.subscribeToWebSocketEvent(
			FavouriteRemoved.eventName,
			new WebSocketFavouriteRemovedSubscriber(
				this.eventAggregationService,
				this.wishlistCache,
			),
		);
	}
}
