export class InvalidRefreshTokenError extends Error {

	public name: string;
	public message: string;

	constructor() {
		super('The token is not a valid refresh token.');
		this.name = 'InvalidRefreshTokenError';

		Object.setPrototypeOf(this, InvalidRefreshTokenError.prototype);
	}
}
