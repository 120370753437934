import { IEvent, IEventAggregationService } from "../../event-aggregation-service";
import { EventAggregationEventType } from "../enums/event-type";

export class WebsocketServiceReadyEvent implements IEvent {

	public readonly timestamp: Date;
	public readonly eventType = WebsocketServiceReadyEvent.EventType;

	public static EventType: string = EventAggregationEventType.WEBSOCKET_SERVICE_READY;

	constructor(
		public eventAggregationService: IEventAggregationService
	) {
		this.timestamp = new Date();
	}
}
