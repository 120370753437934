import { Favourite, ISerializedWishlist, IWishlistRepository, Wishlist } from '@studyportals/wishlist-service-core';

import { ILocalStorageDriver } from '../../common/local-storage-driver.interface';
import { SerializableWishlist } from "../domain/entities/serializable-wishlist";
import { WishlistFactory } from '../domain/factories/wishlist-factory';

export class LocalWishlistRepository implements IWishlistRepository {

	private localStorageKey = 'local-wishlist';
	private wishlistFactory = new WishlistFactory();

	constructor(private localStorageDriver: ILocalStorageDriver) {

	}

	public async addFavourite(wishlist: Wishlist, favourite: Favourite) {
		await this.saveWishlist(wishlist);
	}

	public async removeFavourite(wishlist: Wishlist, favourite: Favourite) {
		await this.saveWishlist(wishlist);
	}

	public async setWishlistOrder(wishlist: any): Promise<void> {
		await this.saveWishlist(wishlist);
	}

	public async retrieveByUserId(userId: string): Promise<Wishlist | null> {
		const localStorageObject = await this.localStorageDriver.get(this.localStorageKey);
		if (localStorageObject === '' || localStorageObject === null) {
			return null;
		}

		const serializedWishlist: ISerializedWishlist = JSON.parse(localStorageObject);
		if (serializedWishlist === null || typeof serializedWishlist.userId === 'undefined') {
			return null;
		}

		return this.wishlistFactory.translateSerializedWishlist(serializedWishlist);
	}

	public async changeUserId(oldUserId: string, newUserId: string): Promise<void> {
		throw new Error('Not available on local wishlist');
	}

	public async createWishlist(userId: string): Promise<Wishlist> {
		const wishlist = new SerializableWishlist(userId);
		await this.saveWishlist(wishlist);
		return wishlist;
	}

	public async removeWishlist() {
		await this.localStorageDriver.remove(this.localStorageKey);
	}

	private async saveWishlist(wishlist: Wishlist) {
		await this.localStorageDriver.set(this.localStorageKey, JSON.stringify(wishlist), 1000000000000);
	}

}
