export class BrowserSessionStorage {

	getItem(key: string): string | null {
		try {
			return window.sessionStorage.getItem(key);
		} catch(error){
			console.error(error);
			return null;
		}
	}

	setItem(key: string, value: any): void {
		try {
			window.sessionStorage.setItem(key, value);
		} catch (e) {
			console.error(e);
		}
	}
}
