import { WishlistOrderChanged } from '@studyportals/wishlist-service-core';
import { IEventAggregationService } from 'interfaces/event-aggregation-service/event-aggregation-service.interface';
import { WishlistCache } from '../infrastructure/wishlist-cache';
import { WebsocketWishlistOrderChangedSubscriber } from './websocket-wishlist-order-changed-subscriber';

export class WebsocketWishlistOrderChangedAdapter {

	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache) {
	}

	public initialize(): void {
		this.eventAggregationService.subscribeToWebSocketEvent(
			WishlistOrderChanged.eventName,
			new WebsocketWishlistOrderChangedSubscriber(
				this.eventAggregationService,
				this.wishlistCache,
			),
		);
	}
}
