import {
	FavouriteAdded,
	FavouriteRemoved,
	IEventEmitter,
	WishlistOrderChanged
} from '@studyportals/wishlist-service-core';
import { DomainEvent } from 'hex-architect';
import { IEventAggregationService } from '../../../interfaces';

export class EventAggregationServiceEventEmitter implements IEventEmitter {

	constructor(public eventAggregationService: IEventAggregationService) {

	}

	public async emit(eventName: string, event: DomainEvent): Promise<void> {
		switch (event.constructor) {

			case FavouriteAdded:
				this.eventAggregationService.publishFavouriteAddedEvent(event as FavouriteAdded);
				break;

			case FavouriteRemoved:
				this.eventAggregationService.publishFavouriteRemovedEvent(event as FavouriteRemoved);
				break;

			case WishlistOrderChanged:
				this.eventAggregationService.publishWishlistOrderChangedEvent(event as WishlistOrderChanged);
				break;

			default:
				console.error('Unknown event triggered');
		}
	}

}
