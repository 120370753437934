import { FavouriteAdded } from '@studyportals/wishlist-service-core';
import { IEventAggregationService } from '../../../interfaces/event-aggregation-service/event-aggregation-service.interface';
import { ISubscriber } from '../../../interfaces/event-aggregation-service/subscriber.interface';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class WebSocketFavouriteAddedSubscriber implements ISubscriber<FavouriteAdded> {

	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private readonly wishlistCache: WishlistCache) {
	}

	async notify(event: FavouriteAdded): Promise<void> {

		await this.wishlistCache.clearCache();

		this.eventAggregationService.publishFavouriteAddedEvent(
			new FavouriteAdded(
				event.userId,
				event.studyId,
				event.eventId,
				new Date(event.dateAdded),
			),
		);
	}
}
