import {
	IEventAggregationService,
	ISubscriber,
} from '../../../../interfaces';
import {
	ISession,
	SessionCreatedEvent,
	SessionServiceReadyEvent,
} from '../../../../interfaces';;

export class GoogleAnalyticsIdentitySubscriber implements ISubscriber<SessionServiceReadyEvent | SessionCreatedEvent> {

	constructor(
		private eventAggregationService: IEventAggregationService,
	) {

	}

	public subscribe(): void {
		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
		this.eventAggregationService.subscribeTo(SessionCreatedEvent.EventType, this, true);
	}

	public async notify(event: SessionServiceReadyEvent | SessionCreatedEvent): Promise<void> {
		if (event.eventType === SessionCreatedEvent.EventType) {
			return this.initializeGoogleAnalyticsIdentityId((event as SessionCreatedEvent).session);
		}

		if (event.eventType === SessionServiceReadyEvent.EventType) {
			await this.handleSessionServiceReadyEvent(event as SessionServiceReadyEvent);
		}

	}

	private async handleSessionServiceReadyEvent(event: SessionServiceReadyEvent): Promise<void> {
		const sessionService = (event as SessionServiceReadyEvent).sessionService;
		const session = await sessionService.getSession();

		if (session === null) {
			return;
		}

		this.initializeGoogleAnalyticsIdentityId(session);
	}

	private initializeGoogleAnalyticsIdentityId(session: ISession): void {
		if (typeof window['ga'] !== 'undefined') {
			window['ga']('set', 'userId', session.getUser().identityId);
		}
	}

}
