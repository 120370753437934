import { DataStorage } from '@studyportals/data-storage';

import { ILocalStorageDriver } from './local-storage-driver.interface';

export class DataStorageDriver implements ILocalStorageDriver {

    async get(key: string): Promise<any> {
        return DataStorage.retrieve(key);
    }

    async set(key: string, value: any, ttl?: number): Promise<any> {
        return DataStorage.store(key, value, ttl);
    }

    async remove(key: string): Promise<void> {
        return DataStorage.remove(key);
    }

}
