import { IEvent } from '../../../../interfaces';
import { InternalSessionServiceEventType } from '../enums/internal-session-service-event-type';

export class SessionRefreshedEvent implements IEvent {
	public static EventType: string = InternalSessionServiceEventType.SESSION_REFRESHED;

	public readonly timestamp: Date;
	public readonly eventType: string = SessionRefreshedEvent.EventType;

	constructor() {
		this.timestamp = new Date();
	}
}
