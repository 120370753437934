import { IEventAggregationService } from 'interfaces/event-aggregation-service/event-aggregation-service.interface';
import { ITokenBasedSession } from '../../../../interfaces';
import { CatchReportAsyncException } from '../../decorators/error-decorator';
import { Credentials } from '../entities/credentials';
import { InvalidAccessTokenError } from '../errors/invalid-access-token-error';
import { InvalidRefreshTokenError } from '../errors/invalid-refresh-token-error';
import { SessionNotFoundError } from '../errors/session-not-found-error';
import { SessionRepositoryReadyEvent } from '../events/session-repository-ready-event';
import { ITokenBasedSessionFactory } from '../interfaces/factory.interface';
import { ITokenBasedSessionRepository } from '../interfaces/token-based-session-repository.interface';
import { LocalCredentialsStorage } from './storage/local-credentials-storage';

export class TokenBasedSessionRepository implements ITokenBasedSessionRepository {

	private localCredentialStorage = new LocalCredentialsStorage();

	constructor(
		private eventAggregationService: IEventAggregationService,
		private tokenSessionFactory: ITokenBasedSessionFactory
	) {

	}

	@CatchReportAsyncException
	public async initialize(): Promise<void> {

		try {
			const localSession = await this.get();
			await this.save(localSession);
		} catch (error) {
			//Ignore error
		}

		this.eventAggregationService.publishTo(SessionRepositoryReadyEvent.EventType, new SessionRepositoryReadyEvent());
	}

	public async get(): Promise<ITokenBasedSession> {
		try {

			const credentials: Credentials = await this.getCredentials();
			if (credentials === null) {
				throw new SessionNotFoundError('There is no session in the session store');
			}

			return this.tokenSessionFactory.create(credentials);
		} catch (error) {

			if (error instanceof InvalidAccessTokenError ||
				error instanceof InvalidRefreshTokenError ||
				error instanceof SessionNotFoundError) {
				throw error;
			}

			throw new SessionNotFoundError(error.message, error);
		}
	}


	public async save(session: ITokenBasedSession): Promise<void> {
		const credentials = await session.getCredentials();
		this.localCredentialStorage.storeCredentials(credentials);
	}

	public async clear(): Promise<void> {
		this.localCredentialStorage.clearCredentials();
	}

	private async getCredentials(): Promise<Credentials> {
		return this.localCredentialStorage.getCredentials();
	}

}
