import { ISubscriber } from '../../interfaces/event-aggregation-service/subscriber.interface';

export class WebSocketGatewaySubscriber<T> {

    constructor(
        public readonly eventType: string | RegExp,
        public readonly handler: ISubscriber<T>,
    ) { }

    public canHandle(eventType: string) {

        if (typeof this.eventType === 'string') {

            return this.eventType.toLowerCase() === eventType.toLowerCase();
        } else {

            return this.eventType.test(eventType);
        }
    }

    public handle(event: T) {

        this.handler.notify(event);
    }
}
