import { ISubscriber } from '../../interfaces/event-aggregation-service';

export class GroupEventEmitter {

	private subscribers: Map<string, Array<ISubscriber<any>>>;
	private pastEvents: Map<string, any[]>;

	constructor() {
		this.subscribers = new Map<string, Array<ISubscriber<any>>>();
		this.pastEvents = new Map<string, any[]>();
	}

	public subscribe(eventType: string, subscriber: ISubscriber<any>, replay: boolean = false): void {
		const subscribers = this.subscribers.get(eventType) || [];
		subscribers.push(subscriber);
		this.subscribers.set(eventType, subscribers);

		if (replay) {
			this.replayEvents(eventType, subscriber);
		}
	}

	public notify(eventType: string, event?: any): void {
		const subscribers = this.subscribers.get(eventType);
		if (subscribers) {
			subscribers.forEach((subscriber) => subscriber.notify(event));
		}

		const pastEvents = this.pastEvents.get(eventType) || [];
		pastEvents.push(event);
		this.pastEvents.set(eventType, pastEvents);
	}

	private replayEvents(eventType: string, subscriber: ISubscriber<any>): void {
		const events = this.pastEvents.get(eventType) || [];
		for (let event of events) {
			subscriber.notify(event);
		}
	}
}
