import { IEventAggregationService } from '../../../../interfaces/event-aggregation-service';
import { ITokenBasedSession } from '../../../../interfaces/session-management';
import { CatchReportException } from "../../decorators/error-decorator";
import { TokenBasedSession } from '../entities/token-based-session';
import { Credentials } from '../entities/credentials';
import { ITokenService } from '../interfaces/token-service.interface';
import { ITokenBasedSessionFactory } from '../interfaces/factory.interface';

export class TokenBasedSessionFactory implements ITokenBasedSessionFactory {

	private eventAggregationService: IEventAggregationService;
	private tokenService: ITokenService;

	constructor(eventAggregationService: IEventAggregationService, tokenService: ITokenService) {

		this.eventAggregationService = eventAggregationService;
		this.tokenService = tokenService;
	}

	@CatchReportException
	create(credentials: Credentials): ITokenBasedSession {

		const session = new TokenBasedSession(this.tokenService, this.eventAggregationService);

		session.setCredentials(credentials);

		return session;
	}
}
