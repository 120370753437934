import { Wishlist } from "@studyportals/wishlist-service-core";

export class SerializableWishlist extends Wishlist {
	private readonly serializedWishlist:any

	constructor(userId: string, serializedWishlist?:any) {
		super(userId);
		this.serializedWishlist = serializedWishlist;
	}

	public serialize(): object {
		if(this.serializedWishlist){
			return this.serializedWishlist;
		}

		return this;
	}
}
